import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import ClipLoader from 'react-spinners/ClipLoader'
import Colors from '../utils/Colors'

const BUTTON_HEIGHT = 48

const useStyles = createUseStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: BUTTON_HEIGHT,
    borderRadius: BUTTON_HEIGHT / 2,
    backgroundColor: Colors.primaryPink,
    fontWeight: '700',
    color: Colors.white,
    textAlign: 'center',
    cursor: 'pointer',
    border: 'none',
  },
  containerDisabled: {
    backgroundColor: Colors.secondaryPink,
    cursor: 'auto',
  },
  containerLoading: {
    backgroundColor: Colors.secondaryPink,
    cursor: 'auto',
  },
  containerGray: {
    backgroundColor: Colors.secondaryGray,
  },
  containerWhite: {
    backgroundColor: Colors.white,
    color: Colors.primaryPink,
    border: `2px solid ${Colors.primaryPink}`,
  },
  containerTextOnly: {
    backgroundColor: 'transparent',
    color: Colors.primaryCharcoal,
  },
  secondaryText: {
    fontSize: '8px',
    marginTop: 8,
  },
  rightNumber: {
    position: 'absolute',
    top: 10,
    bottom: 10,
    right: 18,
    minWidth: 28,
    borderRadius: 16,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    color: Colors.primaryPink,
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightNumberGray: {
    color: Colors.secondaryGray,
  },
  loadingSpinner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}))

export default ({ disabled, loading, gray, white, textOnly, text, secondaryText, secondaryTextClassName, rightNumber, className, style, onClick, ...rest }) => {
  const classes = useStyles()

  return (
    <button
      {...rest}
      className={classNames(className, classes.container, {
        [classes.containerDisabled]: disabled,
        [classes.containerLoading]: loading,
        [classes.containerGray]: gray,
        [classes.containerWhite]: white,
        [classes.containerTextOnly]: textOnly,
      })}
      style={style}
      onClick={disabled ? null : onClick}>
      {loading && (
        <div className={classes.loadingSpinner}>
          <ClipLoader
            color={Colors.primaryPink}
            loading={loading}
            size={32} />
        </div>
      )}
      <div>
        {text}
        {secondaryText && (
          <div className={classNames(classes.secondaryText, secondaryTextClassName)} >{secondaryText}</div>
        )}
      </div>
      {typeof rightNumber === 'number' && (
        <div className={classNames(classes.rightNumber, {
          [classes.rightNumberGray]: gray,
        })}>
          {rightNumber}
        </div>
      )}
    </button>
  )
}
